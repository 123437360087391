import { Link } from 'routes/components/Shared'
import { LINKS } from 'routes/constants'
import NetworkMobImage1x from './landing-network-mob@1x.png'
import NetworkMobImage2x from './landing-network-mob@2x.png'
import NetworkDeskImage1x from './landing-network-desk@1x.png'
import NetworkDeskImage2x from './landing-network-desk@2x.png'
import clsx from 'clsx'
import classes from './LandingNetwork.module.scss'

export function LandingNetwork({
  totalIntegrations,
}: {
  totalIntegrations: number
}) {
  return (
    <section className={clsx('page__section', classes.section)}>
      <div className={clsx('flex-column', classes.heading)}>
        <h2 className={clsx('page__title', classes.title)}>
          Make your app talk to everything else your customers use
        </h2>
        <p className={clsx('page__subtitle', classes.subtitle)}>
          From getting development sandboxes to managing millions of customer connections in production -
          this is the best connectivity your SaaS product can get.
        </p>
      </div>

      <div className={classes.visual}>
        <div className={clsx('page__background', classes.background)} />
        <div className={classes.wrapper}>
          <picture className={classes.image}>
            <source
              media='(min-resolution: 2dppx) and (min-width: 768px)'
              srcSet={NetworkDeskImage2x.src}
            />
            <source
              media='(min-width: 768px)'
              srcSet={NetworkDeskImage1x.src}
            />
            <source
              media='(min-resolution: 2dppx) and (max-width: 767px)'
              srcSet={NetworkMobImage2x.src}
            />
            <img
              src={NetworkMobImage1x.src}
              alt='Supported Integrations'
              loading='lazy'
            />
          </picture>
        </div>
        <div className={clsx('flex-column', classes.counter)}>
          <p className={classes.counter_text}>Supported integrations:</p>
          <div className={classes.counter_wrapper}>
            <div className={classes.counter_number}>
              <span className={classes.counter_number__base}>000000</span>
              <span className={classes.counter_number__main}>
                {totalIntegrations}
              </span>
            </div>
          </div>
          <p className={classes.counter_text}>...and counting</p>
        </div>
      </div>

      <div className={clsx('page__actions', classes.actions)}>
        {/* ↓↓↓ this link to the page that will be added in future ↓↓↓ */}
        {/* <Link
          className={clsx('page__button', classes.link)}
          type='primary'
          href={'#'}
        >
          Read more about our integration network
        </Link> */}
        <Link
          className={clsx('page__button', classes.link)}
          type='secondary'
          href={LINKS.INTEGRATIONS}
        >
          Explore integrations
        </Link>
      </div>
    </section>
  )
}
